import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security, SecureRoute, ImplicitCallback } from '@okta/okta-react';

import Home from './components/home/Home'
import Invoice from './components/invoice/Invoice'
import Dashboard from './components/dashboard/Dashboard'
import OktaLogin from "./components/login/OktaLogin"

import HomeBackground from "./assets/sleigh-background-teal-pink.svg"
import Background from "./assets/sleigh-background-teal-pink-dashboard.svg"


import './components/layout/Main.css';

const config = {
    issuer: 'https://dev-529133.okta.com/',
    redirect_uri: window.location.origin + '/implicit/callback',
    client_id: '0oawnwor0irfPhvsN356'
}

function onAuthRequired({history}) {
  history.push('/login');
}

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
        headerBg: `url(${Background})`,
        reloadKey: Math.random()
    }

    this.changeHeader = this.changeHeader.bind(this);

  }

  reload = () => {
    this.setState({
        reloadKey: Math.random()
    })
  } 

  changeHeader(currentPath) {
    if(currentPath === "/") {
        this.setState({headerBg: `url(${HomeBackground})`})

    } else if(currentPath === "/login") {
        this.setState({headerBg: "none"})

    } else {
        this.setState({headerBg: `url(${Background})`})

    }
        
}
  render() {
    // let params = new URLSearchParams(this.props.location.search);
    return (
      <Router className="router">
        <Security 
            issuer={config.issuer +'oauth2/default'}
            client_id={config.client_id}
            redirect_uri={config.redirect_uri}
            onAuthRequired={onAuthRequired}>
            <div className="App">
              <div id="content">
                <Route path='/' exact={true} component={Home} />
                <SecureRoute 
                    path={'/invoice'} 
                    render={(props) => <Invoice {...props} changeStyle={this.changeHeader}  />} />
                <SecureRoute 
                    path={'/dashboard'} 
                    render={(props) => <Dashboard {...props} key={this.state.reloadKey} reload={this.reload} changeStyle={this.changeHeader}  />} />
                <Route path={'/login'}  render={() => <OktaLogin baseUrl='https://dev-529133.okta.com' />} />
                <Route path='/implicit/callback' component={ImplicitCallback} />
              </div>
              <footer id="foot" ref={(el) => this.footer = el}>
                <p>&copy; 2019 Sleigh Group</p>
              </footer>
            </div>
          
        </Security>
      </Router>
    );
  }
}

export default App;
