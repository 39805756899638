import React, { Component } from 'react'
import MainTable from "../table/MainTable.js"
import "./Home.css"
import { withAuth } from '@okta/okta-react';
import Navbar from '../layout/Navbar'
import "../layout/Main.css"
import Data from "../../airtable-data/Data"


export default withAuth(class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected_project: null,
            dataFetch: new Data(),
            companyData: {},
            contracts: [],
            data: {},
            isLoading: true,
            authenticated: null,
            userinfo: null,
            contractService: {}
        }

        this.getData = this.getData.bind(this)

    }

    getData(company_id) {
        // Gets data
        this.state.dataFetch.findData('Companies', company_id)
        .then(data => {
            this.setState({
                companyData: data.fields,
                contracts: data.fields.Contracts
            })         
        })
        .then(() => {
            if(this.state.contracts === undefined) {
                // render table now as there is no data to load
                this.setState({
                    isLoading: false
                })
            } else {
                // Home page data
                this.state.contracts.forEach(contractID => {
        
                    // Gets contracts data
                    this.state.dataFetch.findData('Contracts', contractID)
                    .then(contractData => {
                        let readableContractID = contractData.fields['Contract ID']
                        let projectsIDs = contractData.fields.Projects
                        let services = contractData.fields['Services']
                        
                        this.state.contractService[readableContractID] = services
                        
                        
                        // Initialise data object
                        this.state.data[readableContractID] = [];
                        if(projectsIDs === undefined) {
                            // render table now as there is no data to load
                            this.setState({
                                isLoading: false
                            })
                        } else {
                            projectsIDs.forEach(projectID => {
                                this.state.dataFetch.findData('Projects', projectID)
                                .then(projectData => {
                                    this.state.data[readableContractID] = this.state.data[readableContractID].concat(projectData.fields)
                                    
                                })
                                .then(() => {    
                                    this.setState({
                                        isLoading: false
                                    })
                                })                       
                                .catch(err => console.log(err));

                            })
                        }
                        
                        
                    })
                    .catch(err => console.log(err));

                });
            }

        })
        .catch(err => console.log(err));
    }

    checkAuthentication = async () => {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            if (authenticated && !this.state.userinfo) {
                const userinfo = await this.props.auth.getUser();
                this.setState({authenticated, userinfo});
            } else {
                this.setState({authenticated});
            }
        }
      };
  
      async componentDidMount() {
        await this.checkAuthentication();
        if(this.state.authenticated) {
            this.getData(this.state.userinfo.CompanyAirtableID)
        }

      }
  
      async componentDidUpdate() {
        this.checkAuthentication();
      }
  
      login = async () => {
        this.props.auth.login('/');
      };
  
      logout = async () => {
        this.props.auth.logout('/');
      };
  


    render() {
        var previewContent, tableContent;
        if(this.state.selected_project === null) {
            previewContent = 
                <div className="none-selected">
                    <h2>Hi, {this.state.companyData['Company Name']}</h2>
                    <p>Select the projects under 'Project Name' to track project progress</p>
                </div>
        }



        if(this.state.isLoading) {
            tableContent =  <i style={{fontSize:"2em"}} className="fa fa-spinner fa-spin"></i>
        } else {
            tableContent = 
            <MainTable tablename="Projects" col1name="CONTRACT ID" col2name="Project Name" col3name="STATUS" 
            data={this.state.data} companyData={this.state.companyData} services={this.state.contractService}/>
        }

        if (this.state.authenticated === null) return null;

        const mainContent = this.state.authenticated ? (
            <div>
                <div className="preview">
                    {previewContent}
                </div>
                <div className="project-table">
                    {tableContent}
                </div>
            </div>
        ) : (
            <div>
                <h1 className="lead">Welcome!</h1>
                <p className="lead">
                    If you don't have an account with us, <a href="http://sleigh.group/forms/inquiryForm.html">Send us an enquiry.</a>
                </p>
                <button className="auth-btn login-btn" onClick={this.login}>
                    Login
                </button>
            </div>
        )


        return(
            <div>
                <Navbar inHomePage={true} isAuth={this.state.authenticated} logout={this.logout}/>
                <div className="home-container content-item">
                    {mainContent}   
                </div>
            </div>
            
        )

        
    }
});

