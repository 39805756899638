import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import "./Invoice.css"
import MainTable from "../table/MainTable.js"
import Navbar from '../layout/Navbar'
import { withAuth } from '@okta/okta-react';


import Data from "../../airtable-data/Data"
import "../layout/Main.css"

export default withAuth(class Invoice extends Component {

    constructor(props) {
        super(props)

        this.state = {
            dataFetch: new Data(),
            companyData: {},
            projects: [],
            data: {},
            isLoading: true,
            userinfo: null,
            authenticated: null
        }

        this.getData = this.getData.bind(this)

    }

    getData(company_id) {
        // Gets data
        this.state.dataFetch.findData('Companies', company_id)
        .then(data => {
            this.setState({
                companyData: data.fields,
                projects: data.fields.Projects
            })
                   
        })
        .then(() => {
            if(this.state.projects === undefined) {
                // render table now as there is no data to load
                this.setState({
                    isLoading: false
                })
            } else {
                // Home page data
                this.state.projects.forEach(projectID => {

                    // Gets data
                    this.state.dataFetch.findData('Projects', projectID)
                    .then(projectData => {
                        let projectName = projectData.fields['Project Name']
                        let invoiceIDs = projectData.fields.Invoice

                        // Initialise data object
                        this.state.data[projectName] = [];

                        if(invoiceIDs === undefined) {
                            // render table now as there is no data to load
                            this.setState({
                                isLoading: false
                            })
                        } else {
                            invoiceIDs.forEach(invoiceID => {
                                this.state.dataFetch.findData('Invoice', invoiceID)
                                .then(invoiceData => {
                                    this.state.data[projectName] = this.state.data[projectName].concat(invoiceData.fields)

                                })
                                .then(() => {                            
                                    this.setState({
                                        isLoading: false
                                    })
                                })                       
                                .catch(err => console.log(err));

                            })
                        }
                        
                        
                        
                    })
                    .catch(err => console.log(err));

                });  
            }
            
        })
        .catch(err => console.log(err));
    }

    checkAuthentication = async () => {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            if (authenticated && !this.state.userinfo) {
                const userinfo = await this.props.auth.getUser();
                this.setState({authenticated, userinfo});
            } else {
                this.setState({authenticated});
            }
        }
      };
  
      async componentDidMount() {
        await this.checkAuthentication();
        this.getData(this.state.userinfo.CompanyAirtableID)

      }
  
      async componentDidUpdate() {
        this.checkAuthentication();
      }


    render() {
        var tableContent;
        if(this.state.isLoading) {
            tableContent =  <i className="fa fa-spinner fa-spin"></i>
        } else {
            tableContent = 
            <MainTable 
                tablename="Invoices" col1name="PROJECT NAME" col2name="Invoice File" col3name="STATUS" 
                data={this.state.data} services="none"/>
        }

        return(
            <div>
                <Navbar isAuth={true}/>
                <div className="invoice-container content-item">
                    <NavLink to="/">
                        <h3 className="dir-link">Home</h3>
                    </NavLink>
                    {tableContent}
                    
                </div>
            </div>
            
        )
    }
})

