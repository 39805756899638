import React, { Component } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'


import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/list/main.css';
import Data from "../../airtable-data/Data"

import "./CalendarApp.css"


class CalendarApp extends Component {

    constructor(props) {
        super(props)

        this.state = {
            dataFetch: new Data(),
            isLoading: true,
            events: []
        }
    }

    componentDidMount(){
        if(this.props.eventData !== undefined) {
            this.props.eventData.forEach(taskID => {
                this.state.dataFetch.findData('Tasks', taskID)
                .then(data => {
                    var eventObj = {}
                    eventObj['title'] = data.fields['Name']

                    // if there is no start date specified
                    if(data.fields['Start Date'] === undefined) {
                        eventObj['date'] = data.fields['Due Date']
                    } else {
                        eventObj['start'] = data.fields['Start Date']
                        eventObj['end'] = data.fields['Due Date']
                    }

                    this.setState((prevState) => {
                        return {
                            events: prevState.events.concat(eventObj)
                        }
                    })
                })
                .then(() => {
                    this.setState({
                        isLoading: false
                    })
                })
                .catch(err => console.log(err))
            })
            
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

  render() {
    var output;
    
    if(!this.state.isLoading) {
        output = 
        <FullCalendar id="calendar"
        header= {{
            right: 'today prev,next',
            center: 'title',
            left: 'dayGridMonth,listWeek'
        }}
        defaultView="dayGridMonth" 
        plugins={[ dayGridPlugin, timeGridPlugin, listPlugin]}
        events={this.state.events}
        eventTextColor= '#fff'
        eventColor= '#FA7268'
        height= 'auto'
        contentHeight= 'auto'
     />
    }
    

    return (
        <div>
            {output}
        </div>
    )
  }

}

export default CalendarApp;