import React, { Component } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Data from "../../airtable-data/Data";
import "./Progressbox.css";
import FormModal from "../form/FormModal";

class ProgressBox extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataFetch: new Data(),
            completeCount: 0,
            isLoading: true,
            tasks: [],
            percentage: 0,
            addTaskClicked: false
        }

        this.showAddTaskForm = this.showAddTaskForm.bind(this)
        this.onCancelTask = this.onCancelTask.bind(this)

    }

    componentDidMount() {
        if(this.props.taskData !== undefined) {
            this.props.taskData.forEach(taskID => {
                this.state.dataFetch.findData('Tasks', taskID)
                .then(data => {
                    this.setState((prevState) => {
                        return {
                            tasks: prevState.tasks.concat(data.fields)
                        }
                    })

                    if(data.fields['Status'] === 'Completed') {
                        this.setState((prevState) => {
                            return {
                                completeCount: prevState.completeCount + 1
                            }
                        })
                    }
                })
                .then(() => {
                    this.setState({
                        isLoading: false,
                        percentage: (this.state.completeCount/this.state.tasks.length) * 100
                    })

                    // find if any task that has an issue, if it exist 
                    for(let task of this.state.tasks) {
                        if(task['Status'] === 'Alert') {
                            this.alertFlag.style.display = "flex";
                            break;
                        }
                    }
                })
                .catch(err => console.log(err))
            });
        } 
        
    }

    showAddTaskForm() {
        this.setState({
            addTaskClicked: true
        });
    }

    onCancelTask() {
        this.setState({
            addTaskClicked: false
        });
    }
    

    render() {
        var rows;

        if(!this.state.isLoading) {
            var status
            if(this.state.tasks.length !== 0) {
                rows = []
                for(let task of this.state.tasks) {
                    var statusStyle = {
                        fontWeight: "bold",
                    }

                    status = task['Status']

                    if(task['Status'] === 'Alert') {
                        status = 
                            <div>
                                <i 
                                    style={{textAlign: "center"}} 
                                    className="fas fa-exclamation-circle" 
                                    onMouseEnter={() => this.popupAlert.style.display = 'block'}
                                    onMouseLeave={() => this.popupAlert.style.display = 'none'}>
                                </i>
                                <div className="popup-alert" ref={(el)=> this.popupAlert = el} hidden>There's an issue for this task. Please contact the Project manager</div>
                            </div>
                    } else if(task['Status'] === 'Completed') {
                        statusStyle = {
                            fontWeight: "bold",
                            color: "#41B3A3"
                        }
                    } else if(task['Status'] === 'Pending') {
                        statusStyle = {
                            color: "#ababab",
                            fontWeight: "bold"
                        }
                    }

                    rows.push(
                        <tr key={task['Name']+task['Due Date']}>
                            <td className="task-name">{task['Name']}</td>
                            <td style={statusStyle}>{status}</td>
                            <td>{task['Start Date']}</td>
                            <td>{task['Due Date']}</td>
                        </tr>
                    )
                }
            }
            
            
        }

        var addBtn = 
            <div className="add-new-container" onClick={this.showAddTaskForm}>
                <div className="add-link" style={{fontFamily: "Montserrat"}}>
                    <div className="add-circle-btn"><i className="fas fa-plus"></i></div> 
                    Add new task
                </div>
            </div>

        return(
            <div className="container prod-progress">
                <h4>
                    Production Progress
                </h4>

                <div className="prod-progress-content">
                    <div className="alert-flag" ref={(el) => this.alertFlag = el}>
                        <i 
                            style={{textAlign: "center", fontSize: "20px"}} 
                            className="fas fa-exclamation-circle" 
                        >
                        </i>
                        <p style={{textAlign: "center", fontSize: "12px"}} >One or more tasks needs immediate actions</p>
                    </div>
                    <CircularProgressbar 
                        value={this.state.percentage} 
                        text={`${Math.round(this.state.percentage)}%`} 
                        styles={{
                            // Customize the root svg element
                            root: {
                                width: '45%',
                                margin: '0 auto'
                            },
                            // Customize the path, i.e. the "completed progress"
                            path: {
                              // Path color
                              stroke: `#FA7268`,
                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              strokeLinecap: 'butt',
                              // Customize transition animation
                              transition: 'stroke-dashoffset 0.5s ease 0s',
                              strokeWidth: '8'

                            },
                            // Customize the circle behind the path, i.e. the "total progress"
                            trail: {
                              // Trail color
                              stroke: `rgba(250, 114, 104, 0.08)`,
                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              strokeLinecap: 'butt',

                             
                            },
                            // Customize the text
                            text: {
                              // Text color
                              fill: '#555',
                              // Text size
                              fontSize: '0.5em',
                            },
                            // Customize background - only used when the `background` prop is true
                            background: {
                              fill: '#3e98c7',
                            },
                          }}/>

                    <div>
                        {addBtn}
                        <FormModal show={this.state.addTaskClicked} cancel={this.onCancelTask} data={this.props.data}
                        reload={this.props.reload} ref={(el) => this.addTaskForm = el}/>
                    </div>
                    
                    <div className="prog-details">
                        <table>
                            <thead>
                                <tr>
                                    <th>Task</th>
                                    <th>Status</th>
                                    <th>Start Date</th>
                                    <th>Due date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        
                        </table>
                    </div>
                </div>

            </div>
        )
    }
}

export default ProgressBox;