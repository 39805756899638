import Airtable from 'airtable';
var base = new Airtable({apiKey: 'keyOZwhifE9g0Y5T5'}).base('app2yJJierTblrTCl');



class Data {

    findData(dataName, id) {
        return new Promise((resolve, reject) => {
          base(dataName)
          .find(id)
          .then(res => res)
          .then(data => resolve(data))
          .catch(err => reject(err));
            
        });  
    }

    addTask(company, project, taskName, taskStatus, startDate, dueDate) {
      return new Promise((resolve, reject) => {
        base('Tasks').create({
                "Name": taskName,
                "Start Date": startDate,
                "Due Date": dueDate,
                "Status": taskStatus,
                "Project": [project],
                "Company": company ,
                "Set By": "Client"
              })
        .then(res => res)
        .then(data => resolve(data))
        .catch(err => reject(err));
      })
      
    }
       
    
}

export default Data;


