import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import "./Dashboard.css"
import Data from "../../airtable-data/Data"
import Navbar from '../layout/Navbar'
import "../layout/Main.css"

import CalendarApp from './CalendarApp'
import ProgressBox from "./Progressbox"


class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataFetch: new Data(),
            data: {},
            isLoading: true,
            staffs: [],
            talents: []
       }

        this.getStaffTalentData = this.getStaffTalentData.bind(this)

    }

    componentDidMount() {
        const currentPath = this.props.location.pathname
        this.props.changeStyle(currentPath);

        let params = new URLSearchParams(this.props.location.search);

        let projID = params.get("proj")


        this.state.dataFetch.findData('Projects', projID)
            .then(projData => {
                this.setState({
                    data: projData.fields
                })
            })
            .then(() => {
                this.setState({
                    isLoading: false
                })   
                this.getStaffTalentData(this.state.data['Staff Assigned To'], this.state.data['Talent Assigned'])
            })
    }

    getStaffTalentData(staffIDs, talentIDs) {

        if(staffIDs !== undefined) {
            staffIDs.forEach(staffID => {
                this.state.dataFetch.findData('Staff Members', staffID)
                .then(data => {
                    this.setState((prevState) => {
                        return {
                            staffs: prevState.staffs.concat(data.fields)
                        }
                    })
                })
                .catch(err => console.log(err))
            });
        }
        
        if(talentIDs !== undefined) {
            talentIDs.forEach(talentID => {
                this.state.dataFetch.findData('Talent', talentID)
                .then(data => {
                    this.setState((prevState) => {
                        return {
                            talents: prevState.talents.concat(data.fields)
                        }
                    })
                })
                .catch(err => console.log(err))
            });
        }
        
    }


    render() {
        var staffRow;   
        
        if(!this.state.isLoading) {    
            staffRow = []
            if(this.state.staffs.length !== 0) {
                for(let staff of this.state.staffs) {
                    var link
                    if(staff['Email'] !== undefined) {
                        link = <td className="staffName"><a href={"mailto:"+staff['Email']}>{staff['Name']}</a></td>
                    } else {
                        link = <td className="staffName">{staff['Name']}</td>
                    }

                    staffRow.push(
                        <tr key={staff['Designation/Role']+staff['Name']}>
                            <td>{staff['Designation/Role']}</td>
                            {link}
                        </tr>
                    )
                }
            }
            if(this.state.talents.length !== 0 ) {
                for(let talent of this.state.talents) {

                    staffRow.push(
                        <tr key={talent['Designation/Role']+talent['Name']}>
                            <td>{talent['Designation/Role']}</td>
                            <td className="staffName">{talent['Name']}</td>
                        </tr>
                    )
                }
            }
            
            
        }

        return(
            <div>
                <Navbar isAuth={true}/>
                <div className="dashboard-container content-item">
                    <NavLink to="/">
                        <h3 className="dir-link">Home</h3>
                    </NavLink>
                    <div className="project-header">
                        <div className="project-title">
                            <h2 className="project-name">
                                {this.state.data["Project Name"]}
                            </h2>
                            <p className="status-desc">
                                Status: {this.state.data["Status"]}, Last updated: {this.state.data["Date Updated"]}
                            </p>
                        </div>

                        {/* <button className="invoice-btn">Invoice</button> */}
                
                    </div>

                    <div className="dashboard-content">
                        <div className="lg-items-container">
                            {this.state.isLoading ? (
                                <div></div>
                                ) : ( 
                                <ProgressBox taskData={this.state.data.Tasks} data={this.state.data} reload={this.props.reload}/>
                            )}
                        
                        </div>

                        
                        <div className="sm-items-container">
                            <div className="container staff-list">
                                <h4>
                                    Staff and Talents
                                </h4>
                                <table className="staff-ls-table">
                                    <thead>
                                        <tr>
                                            <th>Designation</th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {staffRow}
                                    </tbody>
                                    
                                </table>

                            </div>

                            <div className="container contact">
                                <h4>
                                    Chat with us on
                                </h4>
                                <a className="slack-link" href={this.state.data['Chat Link']} target='_blank' rel="noopener noreferrer">
                                    <span></span>
                                </a>

                            </div>

                            <div className="container calendar">
                                {this.state.isLoading ? (
                                    <div></div>
                                    ) : ( 
                                    <CalendarApp eventData={this.state.data.Tasks}/>
                                )}
                            </div>

                            
                        </div>
                    </div>
                </div>  
            </div>
            
        )
    }
}

export default Dashboard;