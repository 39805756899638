import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';

import './Main.css'

import HomeBackground from "../../assets/sleigh-background-teal-pink.svg"
import Background from "../../assets/sleigh-background-teal-pink-dashboard.svg"
// import SmallBackground from "../../assets/sleigh-background-login1.svg"


export default withAuth(class Navbar extends Component {

    componentDidMount() {

        // var smallScreen = window.matchMedia("(max-width: 900px)")


        if(this.props.inLoginPage || !this.props.isAuth) {
            this.nav.style.display = "none"
        } else {
            this.nav.style.display = "flex"
        }

        
        if(this.props.inHomePage) {
            this.header.style.backgroundImage = `url(${HomeBackground})`
        } else {
            this.header.style.backgroundImage = `url(${Background})`

        }
        

        

    }
    login = async () => {
        this.props.auth.login('/');
      };
  
  
    logout = async () => {
        let loginRedirect = this.login
        // Redirect to '/' after logout
        //if session is active
        this.props.auth.logout('/')
        .then(function (out){
            console.log("session is deleted");
            loginRedirect();
        })
        .catch(function (error) {
            // //if session is inactive
            // loginRedirect();

            // Silently ignore no such session errors
            if(error.errorCode !== "E0000007") { 
                throw error;
                } 
        })

    };
  

    render() {


        return(
            <div id="header" ref={(el) => this.header = el} >
                <NavLink to="/">
                    <img id="logo" src={require("../../assets/SleighLogo-White.png")} alt="Sleigh Group" />
                </NavLink>

                <nav id="main-nav" ref={(el) => this.nav = el}>
                    <div className="nav-item invoice">
                        <NavLink to={'/invoice'}>
                            <i className="fas fa-file-alt"></i>
                        </NavLink>                        
                    </div>
                    {/* <div className="nav-item message">
                        <i className="fas fa-comments"></i>
                    </div> */}
                    <div className="nav-item profile" onClick={this.logout}>
                        <button className="auth-btn logout-btn" onClick={this.logout}>
                            Log out
                        </button>
                    </div>
                </nav>
            </div>
        )
    }
})

// export default Navbar;