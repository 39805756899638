import React, { Component } from 'react'
import Data from "../../airtable-data/Data"
import './FormModal.css'
import $ from "jquery"


class FormModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataSet: new Data(),
            submitted: false
        }

        this.handleSubmit = this.handleSubmit.bind(this)

    }

    handleSubmit(e) { 
        e.preventDefault()

        var taskName = this.inputTaskName.value
        var taskStatus = this.inputTaskStatus.options[this.inputTaskStatus.selectedIndex].text
        var startDate = this.inputStartDate.value.length > 0 ? (this.inputStartDate.value) : (null)
        var dueDate = this.inputDueDate.value.length > 0 ? (this.inputDueDate.value) : (null)

        this.state.dataSet
            .addTask(this.props.data['Company'], this.props.data['Record Id'], taskName, taskStatus, startDate, dueDate)
            .then(
                this.setState({
                    submitted: true
                })
            )

    }


    render() {

        var output;

        if(this.props.show) {
            this.form.style.display = 'block'
        } else if(!this.props.show && this.form !== undefined) {
            this.form.style.display = 'none'
        }

        if(!this.state.submitted) {
            output = 
            <div>
                <h4 className="form-title">Add new task</h4>
                <form onSubmit={this.handleSubmit}>
                    <fieldset>
                        <label>Task Name</label>
                        <input type="text" name="task-name" required="required" className="input-field" 
                            ref={(el) => this.inputTaskName = el}/>

                        <label>Status</label>
                        <select name="status" required="required" className="input-field" ref={(el) => this.inputTaskStatus = el}>
                            <option value="Pending">Pending</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Completed">Completed</option>
                        </select>
                    </fieldset>

                    <fieldset>
                        <label>Start Date</label>
                        <input type="date" name="start-date" className="input-field" 
                            ref={(el) => this.inputStartDate = el}/>

                        <label>Due Date</label>
                        <input type="date" name="due-date" className="input-field" 
                            ref={(el) => this.inputDueDate = el}/>
                    </fieldset>

                    <div className="form-btn-container">
                        <input type="button" value="Cancel" className="form-btn form-cancel" 
                        onClick={() => { 
                            $('.modal').slideUp()
                            this.props.cancel()
                            }} />
                        <input type="submit" value="Submit" className="form-btn" />  
                    </div>
                </form>     
            </div>
        } else {
            output = 
            <div className="submitted-confirmation">
                <div className="confirmation-text">
                    <i className="fas fa-check-circle" style={{color: "#41B3A3", fontSize: "3em", marginRight: "20px"}}></i>
                    <h3>New task have been added!</h3>
                </div>
                
                
                <button className="form-done form-btn"
                    onClick={() => { 
                        $('.modal').slideUp()
                        this.props.reload()
                        this.props.cancel()
                        }}>
                            Return to Dashboard
                </button>
            </div>
        }

        return(
            <div className="modal" ref={(el) => this.form = el} hidden>
                <div className="modal-form-container modal-main">
                    {output}
                </div>
            </div>
        )
    }
}

export default FormModal;
