import React, { Component } from 'react'
import "./MainTable.css"
import { Link } from 'react-router-dom';


class MainTable extends Component {

    render() {
        var output;
        var addBtn = <div></div>;
        var rows = [];
        var col1lst
        var col2lst
        var col3lst

        if(Object.keys(this.props.data).length === 0) {
            output = <h2>You have no {this.props.tablename} yet</h2>
        } else {
            var datalst = Object.entries(this.props.data);
            for (let [key, values] of datalst) {
                col1lst = []
                col2lst = []
                col3lst = []

                for (let value of values) {
                    var invoiceFile;
                    var invoiceName = "";
                    
                    if(value["Invoice File"] !== undefined) {
                        invoiceFile = value["Invoice File"][0].url
                        invoiceName = value["Invoice File"][0].filename
                    } else {
                        invoiceFile = "#"
                    }


                    var valueID = value[this.props.col2name]
                    if(this.props.tablename === 'Invoices') {
                        col2lst.push(<li key={key + valueID}><a href={invoiceFile} download={invoiceName} target="_blank" rel="noopener noreferrer" className="clickableColVal">{value["Invoice ID"]}</a></li>)
                    } else {
                        col2lst.push(
                        <li key={key + valueID}>
                            <Link 
                            to={{
                                pathname:'/dashboard',
                                search: `?proj=${value['Record Id']}`,
                                state: {
                                    projectData: value
                                }

                            }} 
                            className="clickableColVal">{value[this.props.col2name]}</Link>
                        </li>)
                    }
                    
                    col3lst.push(<li key={key + valueID + "status"} className="status">{value['Status']}</li>)
                }

                if(this.props.services[key] !== undefined && this.props.services !== 'none') {

                    for(let service of Object.values(this.props.services[key])) {
                        col1lst.push(<li key={key + service} className="service">{service}</li>)
                    }

                }

                rows.push(
                    <tr key={key}>
                        <td className="mainCol">
                            <div 
                                className="contract-name" ref={(el) => this.contractName = el}
                                onMouseEnter={(e) => e.target.nextSibling.style.display = 'block'}
                                onMouseLeave={(e) => e.target.nextSibling.style.display = 'none'}>
                                {key}
                            </div>  
                            <div 
                                className="popup-contract" ref={(el) => this.popupContract = el}>
                                <ul>
                                    {col1lst}
                                </ul>
                            </div>
                            
                        </td>
                        <td>
                            <ul>
                                {col2lst}
                            </ul>
                        </td>
                        <td>
                            <ul>
                                {col3lst}
                            </ul>
                        </td>
                    </tr>
                )

            }
            output =
                <table>
                    <thead>
                        <tr>
                            <th>{this.props.col1name}</th>
                            <th>{this.props.col2name.toUpperCase()}</th>
                            <th>{this.props.col3name}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
    
            
        }

        if(this.props.tablename === "Projects") {
            addBtn = 
            <div className="add-new-container">
                <a className="add-link" target="_blank" rel="noopener noreferrer" href={this.props.companyData["Order form"]}>
                    <div className="add-circle-btn"><i className="fas fa-plus"></i></div> 
                    Add new project
                </a>
                <a className="add-link" target="_blank" rel="noopener noreferrer" href={this.props.companyData["Inquiry form"]}>
                    <div className="add-circle-btn"><i className="fas fa-plus"></i></div> 
                    Add new contract
                </a>
            </div>
        }



        return (
        
            <div className="table-container">
                <div className="table-header">
                    <h3>
                    {this.props.tablename} 
                    </h3>
                </div>
                <div className="table-content">
                    {addBtn}
                    {output}
                </div>
            </div>
            
        )
        
    }
}

export default MainTable;